import Http from '@services/httpClient';
import { StartPlayPayload } from '@common/ApiTypes';

export default {
  start(data: StartPlayPayload) {
    return new Promise((resolve, reject) => {
      Http.post({
        url: '/api/frontend/start-play',
        data,
      })
        .then((res) => resolve(res.data))
        .catch((e) => reject(e));
    });
  },
};
