import Http from '@services/httpClient';

export default {
  list() {
    return new Promise((resolve, reject) => {
      Http.get({ url: '/api/frontend/items' })
        .then((res) => resolve(res.data))
        .catch((e) => reject(e));
    });
  },
};
