import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Message } from 'rsuite';

function buildMessage(message: string): JSX.Element {
  return (
    <Message closable type="info">
      {message}
    </Message>
  );
}

function buildErrorMessage(message: string): JSX.Element {
  return (
    <Message closable type="error">
      {message}
    </Message>
  );
}

function withRouter(Component: any): (props: any) => JSX.Element {
  const location = useLocation;
  const params = useParams;
  const navigate = useNavigate;
  // eslint-disable-next-line func-names
  return function (props: any): JSX.Element {
    return (
      <Component
        {...props}
        location={location()}
        navigate={navigate()}
        params={params()}
      />
    );
  };
}

export default {
  withRouter,
  buildErrorMessage,
  buildMessage,
};
